/* ============== New Footer ==============*/
.newFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  position: relative;
  width: 100%;
  bottom: 10px;
  background: transparent;
}

.newFooterInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.newFooterInner p,
.newFooterInner p span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footerLinks {
  margin: 6px 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footerLinks li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--mediumGray);
}
.footerLinks li a {
  font-size: 8px;
  color: var(--mediumGray);
  padding: 0 6px;
  text-decoration: underline;
}

/* ============== New Footer ==============*/

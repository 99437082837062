.StepsBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 232px;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--mediumBlue);
}
.StepsBoxWrapper .easypaisaAppStepsBoxList:last-child {
  margin-bottom: 0;
}

.bankListBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 500px;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--mediumBlue);
}
.bankListBoxWrapper .detailScreenCardMain {
  height: 100%;
  width: 100%;
}
.bankListBoxWrapper .easypaisaAppStepsBoxList:last-child {
  margin-bottom: 0;
}
.bankListBoxWrapper .detailScreenCardInner {
  margin: 0.6rem 0;
}
.bankListBoxWrapper .detailScreenCardInner .detailScreenCard {
  min-height: 39px;
  max-height: 39px;
  height: auto;
  box-shadow: none;
  padding: 0;
  border: 0;
}
.bankListBoxWrapper .detailScreenCardInner .detailScreenCardLeft {
  border: 1px solid var(--extraLightGray);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  padding: 5px 5px;
  filter: none;
}
.bankListBoxWrapper .detailScreenCardInner .detailScreenCardLeft .UdhaarLogo {
  /* width: 30px;
  height: 30px; */
  padding: 3px 3px;
  filter: none;
}
.selectBankBox .input-container.focused .floating-label,
.selectBankBox
  .input-container.focused
  input:not(:placeholder-shown)
  + .floating-label {
  background: var(--lightBlue);
}

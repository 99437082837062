.contactInfoWrapper {
}
.contactInfoInnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 174px;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--extraLightGray);
}

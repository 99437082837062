.easypaisaAppStepsBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* min-height: 316px; */
  margin-bottom: 20px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--mediumBlue);
}
.easypaisaAppStepsBox .itemsListAccordion.accordion {
  width: 100%;
}
.easypaisaAppStepsBox .receiptBoxItemLeft {
  flex: 1 1;
}
.easypaisaAppStepsBox .receiptBoxItemRight {
  flex: 0.3 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.easypaisaAppStepsBox
  .itemsListAccordion
  .accordion-button
  .receiptBoxItemRight
  svg:nth-child(1) {
  margin-right: 10px;
}
.easypaisaAppStepsBox
  .itemsListAccordion
  .accordion-button:not(.collapsed)
  .receiptBoxItemRight
  svg:nth-child(1) {
  transform: rotate(0deg);
}

.easypaisaAppStepsBox .itemsListAccordion .receiptBoxItem {
  border: 0;
  padding: 0;
}
.easypaisaAppStepsBox .itemsListAccordion .accordion-body {
  margin-top: 18px;
  padding: 18px 0 0;
  border-top: 1px solid var(--extraLightGray);
}
.easypaisaAppStepsBoxList {
  margin-bottom: 18px;
}
.easypaisaAppStepsBox .accordion-body .easypaisaAppStepsBoxList:last-child {
  margin-bottom: 0;
}

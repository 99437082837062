@import url(https://fonts.googleapis.com/earlyaccess/notonaskharabic.css);

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--font_Outfit) !important;
}
html,
body {
  height: 100vh;
  /* overflow-y: auto; */
}

:root {
  /* font Family */
  --font_Outfit: "Outfit";
  /* font Family */
  --mainColor-rgb: 59, 12, 54;
  --black: #000;
  --orangeShade: #fef0eb;
  --greenShade: #f1fcd2;
  --mainColor: #3b0c36;
  --brightPurple: "#E5D7E5";
  --extraBrightPurple: "#EEEAEE";
  --red: #e51a32;
  --skyBlue: #27aae1;
  --blueShade: #ecf7fb;
  --mustardYellow: #f9f0dc;
  --mustardYellowShade: #f2bc1d;
  --extraLightGray: #f5f5f7;
  --lightGray: #cecece;
  --lightGrayTwo: #a4a4a4;
  --lightBlue: #f7fbfa;
  --mediumGray: #565656;
  --darkGray: #414143;
  --darkGrayTwo: #7d7d7d;
  --darkGreen: #048900;
  --darkOrange: #ef4136;
  --brightGreen: #4ac600;
  --brightOrange: #f96a32;
  --white: #fff;
  --backgroundShade: #f8fcff;
  --borderColor: #ddd;
  --barclayBlue: #00aeef;
  --brigthRed: #f9eced;
  --grayBgNew: #212423;
  --grayBgTwo: #1a1a1a;
  --grayBgThree: #232323;
  --searGreen: #4db6ac;
  --mediumBlue: #e9f6fc;
}
.businessNamePriceUrdu h5 {
  font-family: "Noto Naskh Arabic", serif;
}

.dateContUrdu span {
  font-family: "Noto Naskh Arabic", serif;
}

.paymetClientMainHeadingUrdu h3 {
  font-family: "Noto Naskh Arabic", serif;
}

.paymentMethodHeadingUrdu h3 {
  font-family: "Noto Naskh Arabic", serif;
}

.paymentMethodSubHeadingUrdu {
  font-family: "Noto Naskh Arabic", serif;
}

.confirmingParaUrdu {
  font-family: "Noto Naskh Arabic", serif;
}

/* .waitingCodeTex {
  font-family: "Noto Naskh Arabic", serif;
} */
.inputDivStyle input,
.inputDivStyle textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
  background: transparent !important;
}

.inputDivStyle input:focus,
.inputDivStyle textarea:focus {
  outline: none;
}

/* LABEL ======================================= */
.inputDivStyle label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.inputDivStyle input:focus ~ label,
.inputDivStyle textarea:focus ~ label {
  top: -15px;
  font-size: 0.9rem;
  color: #3b0c36;
}

.inputDivStyle input:focus ~ label.labelCustomPosition,
.inputDivStyle input:valid ~ label.labelCustomPosition,
.inputDivStyle textarea:focus ~ label.labelCustomPosition,
.inputDivStyle textarea:valid ~ label.labelCustomPosition {
  top: -15px !important;
  font-size: 0.9rem;
}

/* input:focus ~ label, input:valid ~ label {
  top:-20px;
  font-size:14px;
  color:#3B0C36;
} */

/* BOTTOM BARS ================================= */
.inputDivStyle .bar {
  position: relative;
  display: block;
  width: 100%;
}

.inputDivStyle .bar:before,
.inputDivStyle .bar:after {
  content: "";
  height: 1px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #3b0c36;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.inputDivStyle .bar:before {
  left: 50%;
}

.inputDivStyle .bar:after {
  right: 50%;
}

/* active state */
.inputDivStyle input:focus ~ .bar:before,
.inputDivStyle input:focus ~ .bar:after,
.inputDivStyle textarea:focus ~ .bar:before,
.inputDivStyle textarea:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.inputDivStyle input:focus ~ .highlight,
.inputDivStyle textarea:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #3b0c36;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #3b0c36;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #3b0c36;
  }

  to {
    width: 0;
    background: transparent;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paymentSucessScreenMain {
  position: relative;
  top: 60px;
  height: 85vh;
  --mainColor-rgb: 59, 12, 54;
  --orangeShade: #fef0eb;
  --greenShade: #f1fcd2;
  --mainColor: #3b0c36;
  --brightPurple: "#E5D7E5";
  --extraBrightPurple: "#EEEAEE";
  --red: #e51a32;
  --skyBlue: #27aae1;
  --blueShade: #ecf7fb;
  --mustardYellow: #f9f0dc;
  --mustardYellowShade: #f2bc1d;
  --extraLightGray: #f5f5f7;
  --lightGray: #cecece;
  --lightGrayTwo: #a4a4a4;
  --lightBlue: #f7fbfa;
  --mediumGray: #565656;
  --darkGray: #414143;
  --darkGrayTwo: #7d7d7d;
  --darkGreen: #048900;
  --darkOrange: #ef4136;
  --brightGreen: #4ac600;
  --brightOrange: #f96a32;
  --white: #fff;
  --backgroundShade: #f8fcff;
  --borderColor: #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentSucessScreenMain h4 {
  color: #414141;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 20px;
}

.paymentSucessScreenMain .payNowButton {
  text-decoration: none;
}

.paymentSucessScreenMain .payNowButton:hover {
  color: #fff !important;
}

.paymetClientMainHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 20px;
  width: 88%;
}

.paymetClientMainHeading.paymetClientMainHeadingUrdu {
  align-items: flex-end;
}

.paymetClientMainHeading h3 {
  font-size: 20px;
  color: var(--darkGray);
  margin-bottom: 0;
  font-weight: 500;
}

.paymetClientMainHeading h5 {
  font-size: 15px;
  color: var(--mediumGray);
  font-weight: 500;
}

.businessNamePrice {
  margin: 10px 0 0;
  width: 85%;
}

.businessNamePrice.businessNamePriceUrdu {
  text-align: right;
}

.businessNamePrice h5 {
  font-size: 16px;
  color: var(--mediumGray);
  font-weight: 400;
  margin-bottom: 5px;
}

.businessNamePrice h5 span {
  margin-right: 2px;
}

.businessNamePrice h3 {
  font-size: 36px;
  color: var(--darkGray);
  margin-bottom: 0;
  font-weight: 600;
}

.BodyContentTop.paymentFieldSection {
  margin: 5px auto 10px;
  padding: 5px 15px 10px;
  min-height: 450px;
}

.BodyContentTop.paymentFieldSection.verificationScreenBox {
  min-height: 400px;
}

.paymentMethodNewCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: transparent;
  padding: 0 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  margin: 0 auto;
}

.paymentMethodNewCont.paymentMethodNewContUrdu {
  align-items: flex-end;
}

.paymentMethodHeading {
  margin-bottom: 10px;
  width: 100%;
}

.paymentMethodHeading.paymentMethodHeadingUrdu {
  text-align: right;
}

.paymentMethodHeading h3 {
  font-size: 20px;
  color: var(--darkGray);
  margin-bottom: 15px;
  font-weight: 500;
}

.paymentMethodHeading.successHeading {
  text-align: left;
  background: var(--brightGreen);
  padding: 11px 20px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
  margin-bottom: 0;
}

.paymentMethodHeading.successHeading h3 {
  font-size: 20px;
  color: var(--white);
  margin-bottom: 3px;
  font-weight: 500;
}

.successHeadingTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.successHeadingTime span {
  color: var(--white);
  font-size: 14px;
}

.paymentMethodSubHeading {
  font-size: 13px;
  color: var(--mediumGray);
  font-weight: 500;
  margin-bottom: 6px;
}

.paymentMethodSubHeading.paymentMethodSubHeadingUrdu {
  text-align: right;
}

.walletBankSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.radioDisable {
  opacity: 0.5;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
  opacity: 1 !important;
  color: var(--lightGrayTwo) !important;
}

.LoadingScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

button.buttonStyle .sr-only {
  display: none;
}

.LoadingScreen .loaderCircle {
  width: 35px !important;
  height: 35px !important;
  color: var(--mainColor) !important;
}

.LoadingScreen p {
  margin-top: 7px;
  font-size: 17px;
  color: var(--darkGray);
}

.BodyContentBottom {
  float: left;
  margin: 5px 0 5px !important;
  position: relative;
  bottom: 15px;
  left: 0;
  width: 100%;
}

.BodyContentBottom p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #3b0c36;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.BodyContentBottom span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--mediumGray);
}

.contactUsIcon {
  background: url("./assets/images/WhatsApp_icon_Purple.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.BodyContentBottom span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.trustedIcon {
  background: url("./assets/images/100_Secure.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-right: 5px;
}

.secondFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.udhaarIcon {
  background: url("./assets/images/logonew2.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 140px;
  height: 41px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.secondFooter ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0px 0px;
  padding: 0 0px 0;
  width: auto;
}

.secondFooter ul li {
  list-style: none;
}

.secondFooter ul li a {
  color: var(--mediumGray);
  font-size: 13px;
  padding: 0 12px;
  text-decoration: none;
  border-right: 1px solid var(--lightGray);
}

.secondFooter ul li:nth-child(1) a {
  padding: 0 12px 0 0px;
}

.secondFooterBottomSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.secondFooterBottomSection p {
  margin-left: 10px;
  font-size: 13px;
}

.dNone {
  display: none;
}

.cardFormMainBox {
  width: 100%;
}

.backIcon {
  background: url(/src/assets/images/Multiply.svg);
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-size: cover;
}

.verficationBottomSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* margin-top: 20px; */
}

.verifyCodeActionBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paymetClientMainHeading.MpinHeading {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.CloseDisable {
  opacity: 0.5;
}

.BodyContentTopInner.successScreenBody {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
  background: var(--backgroundShade);
  padding: 10px 0;
  border-radius: 0 0 20px 20px;
  position: relative;
}

.mPinText {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mpinScreenImage {
  width: 200px;
  margin: 0 auto;
  min-height: 200px;
  text-align: center;
}

.easyPaisaAppopen {
  font-size: 16px;
  color: var(--mediumGray);
  margin: 20px 0 15px;
  text-align: center;
}

.mPinText .waitingforCode p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mPinText .waitingforCode p span {
  color: var(--mediumGray);
  font-size: 12px;
}

.mPinText .waitingforCode p span:nth-child(2) {
  font-size: 16px;
  margin-top: 12px;
}

.captchaModal .modal-content {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 325px !important;
  border-radius: 10px !important;
  height: 98px;
}

.captchaModalForVoucher .modal-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 400px !important;
  border-radius: 10px !important;
  height: 200px;
  /* background-color: 'black'; */
}

.selectNetworModal .modalBodyContent {
  padding: 30px 0 15px;
}

.modalTelcosMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.modalBodyButton {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: transparent;
  border: 1px solid var(--lightGray);
  box-shadow: 0 0 8px 0 rgb(0, 0, 0, 0, 0.12) !important;
  margin: 0 10px;
  padding: 5px 10px 5px;
  width: 30%;
  min-height: 90px;
}

.modalBodyButton img {
  width: 75%;
  opacity: 0.8;
}

.modalBodyButton:hover img {
  opacity: 1;
}

.modalBodyButton:hover {
  background: transparent;
}

.modalBodyButton i {
  opacity: 0.7;
}

.modalBodyButton:hover i {
  opacity: 1;
}

.modalBodyButton span {
  color: var(--darkGray);
  font-size: 14px;
  text-align: center;
}

.modalBodyButton span:nth-child(1) {
}

.modalBodyButton span:nth-child(2) {
}

.modal-header h5 {
  margin-bottom: 0;
  color: var(--darkGray);
  font-size: 16px;
  font-weight: 400;
}

.selectNetworModal .modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightGray);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  padding: 0.5rem 1rem;
}

.selectNetworModal .modal-content {
  padding: 15px 0px !important;
}

.modaltelecosDescription {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1em;
  color: var(--mediumGray);
}

/* Voucher Flow Styling */
.vouchersSectionBodyBox {
  position: relative;
  top: 60px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
}

.textSection {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.textStyle1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--darkGray);
  margin-bottom: 13px;
}

.textStyle1 img {
  width: 75px;
  margin: 0 4px;
}

.textStyle2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  color: var(--darkGray);
}

.textStyle2 img {
  width: 75px;
  margin: 0 4px;
}

.inputSection {
  display: flex;
  justify-content: center;
  align-content: center;
  min-width: 300px;
}

.voucherCodeTextStyle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 20px 0;
  /* line-height: 16px; */
  text-align: center;
  color: var(--darkGray);
}

.inputDivStyle {
  position: relative;
  width: 100%;
}

.ButtonSection {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
}

.footerTextStyle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration-line: underline;

  /* barclaysBlue */

  color: #00aeef;
}

.inputDivStyle input {
  width: 100%;
  padding: 7px 7px 10px 2px;
  font-size: 1rem;
  color: var(--darkGray);
  border-bottom: 1px solid var(--lightGray);
  background: transparent;
}

.inputDivStyle input:focus .inputDivStyle textarea:focus {
  color: var(--mainColor);
}

.inputDivStyle label {
  color: #565656;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 2px;
  top: 6px;
  text-transform: none;
}

.inputErrorVoucher {
  color: var(--red);
  font-size: 0.8rem;
  margin-bottom: 0;
  font-size: 0.7rem;
  margin-bottom: 0;
  display: flex;
  min-height: 20px;
  position: relative;
  top: 5px;
}

.VoucherCopyCode {
  color: var(--mediumGray);
  font-size: 1rem;
  margin-bottom: 0;
  min-height: 28px;
  position: relative;
  top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.voucherCodeBtn .inputBoxbutton {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Voucher Flow Styling */
.BodyContentBottom p.tokoLabsPara {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -5px;
  margin-bottom: 5px;
  width: 90%;
}

p.tokoLabsPara {
  font-size: 13px;
  color: var(--mediumGray);
}

.langaugeButtonBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 9;
  right: 0;
  top: 0;
  margin-bottom: 5px;
  width: 88%;
}

.langaugeButtonBox button {
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

.seperator {
  height: 18px;
  border: 1px solid var(--lightGray);
  margin: 0 15px;
}

.JcIcon {
  position: relative;
  left: -26px;
}

.test {
  display: flex;
  flex-direction: row-reverse;
}

.englishIcon {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  background: url("assets/images/englishIcon.svg");
  background-size: cover;
}

.englishIconActive {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  background: url("assets/images/englishIconFill.svg");
  background-size: cover;
}

.urduIcon {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  background: url("assets/images/urduIcon.svg");
  background-size: cover;
}

.urduIconActive {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  background: url("assets/images/urduIconfill.svg");
  background-size: cover;
}

.verificationnumberHeading {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.verificationnumberHeading b {
  margin-left: 5px;
}

.waitingforCode.mpinWaitingforCode {
}

.waitingforCode.mpinWaitingforCode span:nth-child(1) {
  margin-right: 2px !important;
}

.waitingforCode.mpinWaitingforCode span:nth-child(2) {
}

.waitingforCode.mpinWaitingforCode span:nth-child(3) {
  margin-left: 3px !important;
}

.waitingforCode.mpinWaitingforCode p {
}

.mpinWaitingforCode .mpinwaitingText {
  color: #565656 !important;
  font-size: 0.8rem !important;
  margin: 0 !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
  .BodyContentTop.paymentFieldSection.verificationScreenBox {
    min-height: 300px;
  }

  .selectNetworModal .modal-content {
    position: absolute;
    bottom: -15px;
    border-radius: 20px 20px 0 0 !important;
  }

  .modalBodyButton {
    width: 50%;
    min-height: 100px;
  }

  .businessNamePrice {
    width: 100%;
  }

  .BodyContentTop.paymentFieldSection {
    padding: 5px 5px 15px;
    min-height: 250px;
  }

  .BodyContentBottom {
    position: relative;
  }

  .BodyContentBottom p.tokoLabsPara {
    justify-content: center;
  }

  .secondFooter {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .secondFooter ul {
    margin: 0 0 0px 15px;
    border-left: none;
  }

  .BodyContentBottom p {
    margin: 0 15px 0px;
    justify-content: center;
  }

  .paymetClientMainHeading {
    width: 100%;
  }

  .langaugeButtonBox {
    width: 100%;
  }

  .secondFooterBottomSection p {
    margin-bottom: 0 !important;
  }

  .secondFooterBottomSection {
    margin-bottom: 20px;
  }

  .paymetClientMainHeading.MpinHeading {
    justify-content: center;
    align-items: flex-start;
  }

  .paymentFieldSection.mpinScreen .backIconMain {
    left: 0 !important;
  }

  .mpinScreen .paymetClientMainHeading.MpinHeading {
    align-items: flex-end;
  }

  .mpinScreen .MpinHeading h3 {
    text-align: right;
  }

  .mpinWaitingforCode .mpinwaitingText {
    color: #565656 !important;
    font-size: 0.67rem !important;
    margin: 0 !important;
  }
}

/* New Design Css */

/* ================ Color ================ */
.mainColor-rgbColor {
  color: rgb(59, 12, 54);
}

.orangeShadeColor {
  color: var(--orangeShade);
}

.greenShadeColor {
  color: var(--greenShade);
}

.mainColorColor {
  color: var(--mainColor);
}

.brightPurpleColor {
  color: var(--brightPurple);
}

.extraBrightPurpleColor {
  color: var(--extraBrightPurple);
}

.redColor {
  color: var(--red);
}

.skyBlueColor {
  color: var(--skyBlue);
}

.blueShadeColor {
  color: var(--blueShade);
}

.mustardYellowColor {
  color: var(--mustardYellow);
}

.mustardYellowShadeColor {
  color: var(--mustardYellowShade);
}

.extraLightGrayColor {
  color: var(--extraLightGray);
}

.lightGrayColor {
  color: var(--lightGray);
}

.lightGrayTwoColor {
  color: var(--lightGrayTwo);
}

.lightBlueColor {
  color: var(--lightBlue);
}

.mediumGrayColor {
  color: var(--mediumGray);
}

.darkGrayColor {
  color: var(--darkGray);
}

.darkGrayTwoColor {
  color: var(--darkGrayTwo);
}

.darkGreenColor {
  color: var(--darkGreen);
}

.darkOrangeColor {
  color: var(--darkOrange);
}

.brightGreenColor {
  color: var(--brightGreen);
}

.brightOrangeColor {
  color: var(--brightOrange);
}

.whiteColor {
  color: var(--white);
}

.backgroundShadeColor {
  color: var(--backgroundShade);
}

.borderColor {
  color: var(--borderColor);
}

.barclayBlueColor {
  color: var(--barclayBlue);
}
.searGreenColor {
  color: var(--searGreen);
}

/* ================ Color ================ */
/* ================ Background Color ================ */

.mainColor-rgbBg {
  background: rgb(59, 12, 54);
}

.orangeShadeBg {
  background: var(--orangeShade);
}

.greenShadeBg {
  background: var(--greenShade);
}

.mainColorBg {
  background: var(--mainColor);
}

.brightPurpleBg {
  background: var(--brightPurple);
}

.extraBrightPurpleBg {
  background: var(--extraBrightPurple);
}

.redBg {
  background: var(--red);
}

.skyBlueBg {
  background: var(--skyBlue);
}

.blueShadeBg {
  background: var(--blueShade);
}

.mustardYellowBg {
  background: var(--mustardYellow);
}

.mustardYellowShadeBg {
  background: var(--mustardYellowShade);
}

.extraLightGrayBg {
  background: var(--extraLightGray);
}

.lightGrayBg {
  background: var(--lightGray);
}

.lightGrayTwoBg {
  background: var(--lightGrayTwo);
}

.lightBlueBg {
  background: var(--lightBlue);
}

.mediumGrayBg {
  background: var(--mediumGray);
}

.darkGrayBg {
  background: var(--darkGray);
}

.darkGrayTwoBg {
  background: var(--darkGrayTwo);
}

.darkGreenBg {
  background: var(--darkGreen);
}

.darkOrangeBg {
  background: var(--darkOrange);
}

.brightGreenBg {
  background: var(--brightGreen);
}

.brightOrangeBg {
  background: var(--brightOrange);
}

.whiteBg {
  background: var(--white);
}

.backgroundShadeBg {
  background: var(--backgroundShade);
}

.borderBg {
  background: var(--borderColor);
}

.barclayBlueBg {
  color: var(--barclayBlue);
}
.searGreenBg {
  background: var(--searGreen);
}
/* ================ Background Color ================ */

/* ================ Font Sizes ================ */
.fontSize10 {
  font-size: 10px;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize17 {
  font-size: 17px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize19 {
  font-size: 19px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize21 {
  font-size: 21px;
}

.fontSize22 {
  font-size: 22px;
}

.fontSize23 {
  font-size: 23px;
}

.fontSize24 {
  font-size: 24px;
}

.fontSize25 {
  font-size: 25px;
}

.fontSize26 {
  font-size: 26px;
}

.fontSize28 {
  font-size: 28px;
}

.fontSize30 {
  font-size: 30px;
}

.fontSize32 {
  font-size: 32px;
}

.fontSize34 {
  font-size: 34px;
}

.fontSize36 {
  font-size: 36px;
}

.fontSize38 {
  font-size: 38px;
}

.fontSize40 {
  font-size: 40px;
}

.fontSize42 {
  font-size: 42px;
}

.fontSize43 {
  font-size: 43px;
}

.fontSize44 {
  font-size: 44px;
}

.fontSize54 {
  font-size: 54px;
}

.fontSize56 {
  font-size: 56px;
}

.fontSize58 {
  font-size: 58px;
}

.fontSize64 {
  font-size: 64px;
}

.fontSize74 {
  font-size: 74px;
}

.fontSize84 {
  font-size: 84px;
}

/* ================ Font Sizes ================ */

/* ================ Font Weight ================ */
.fontWeight100 {
  font-weight: 100;
}

.fontWeight200 {
  font-weight: 200;
}

.fontWeight300 {
  font-weight: 300;
}

.fontWeight400 {
  font-weight: 400;
}

.fontWeight500 {
  font-weight: 500;
}

.fontWeight600 {
  font-weight: 600;
}

.fontWeight700 {
  font-weight: 700;
}

/* ================ Font Weight ================ */

/* ================ Text Decoration ================ */
.textUnderline {
  text-decoration: underline;
}

.textUnderlineNo {
  text-decoration: none;
}

/* ================ Text Decoration ================ */

/* ================ Text Center ================ */
.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

/* ================ Text Center ================ */

/* ================ Border Radius ================ */
.borderRadius10 {
  border-radius: 10px;
}

.borderRadius15 {
  border-radius: 15px;
}

.borderRadius20 {
  border-radius: 20px;
}

.borderRadius25 {
  border-radius: 25px;
}

.borderRadius30 {
  border-radius: 30px;
}

.borderRadius50Percent {
  border-radius: 50%;
}

/* ================ Border Radius ================ */

/* ================ Width ================ */
.width100 {
  width: 100%;
}

.width95 {
  width: 95%;
}

.width90 {
  width: 90%;
}

.width85 {
  width: 85%;
}

.width80 {
  width: 80%;
}

.width75 {
  width: 75%;
}

.width70 {
  width: 70%;
}

.width65 {
  width: 65%;
}

.width60 {
  width: 60%;
}

.width65 {
  width: 65%;
}

.width64 {
  width: 64%;
}

.width63 {
  width: 63%;
}

.width62 {
  width: 62%;
}

.width61 {
  width: 61%;
}

.width60 {
  width: 60%;
}

.width55 {
  width: 55%;
}

.width54 {
  width: 54%;
}

.width53 {
  width: 53%;
}

.width52 {
  width: 52%;
}

.width51 {
  width: 51%;
}

.width50 {
  width: 50%;
}

.width45 {
  width: 45%;
}

.width40 {
  width: 40%;
}

.width35 {
  width: 35%;
}

.width30 {
  width: 30%;
}

.width25 {
  width: 25%;
}

.width20 {
  width: 20%;
}

.width18 {
  width: 18%;
}

.width17 {
  width: 17%;
}

.width16 {
  width: 16%;
}

.width15 {
  width: 15%;
}

.width10 {
  width: 10%;
}

.width5 {
  width: 5%;
}

/* ================ Width ================ */

/* ================ Cursor Pointer ================ */
.cursorPointer {
  cursor: pointer;
}

/* ================ Cursor Pointer ================ */

/* ================ Margin Top ================ */
.marTop0 {
  margin-top: 0px;
}

.marTop5 {
  margin-top: 5px;
}

.marTop10 {
  margin-top: 10px;
}

.marTop15 {
  margin-top: 15px;
}

.marTop20 {
  margin-top: 20px;
}

.marTop25 {
  margin-top: 25px;
}

.marTop30 {
  margin-top: 30px;
}

.marTop35 {
  margin-top: 35px;
}

.marTop40 {
  margin-top: 40px;
}

.marTop45 {
  margin-top: 45px;
}

.marTop50 {
  margin-top: 50px;
}

.marTop55 {
  margin-top: 55px;
}

.marTop60 {
  margin-top: 60px;
}

.marTop65 {
  margin-top: 65px;
}

.marTop70 {
  margin-top: 70px;
}

.marTop75 {
  margin-top: 75px;
}

.marTop80 {
  margin-top: 80px;
}

/* ================ Margin Top ================ */

/* ================ Margin Bottom ================ */
.marBot0 {
  margin-bottom: 0px;
}

.marBot2 {
  margin-bottom: 2px;
}

.marBot3 {
  margin-bottom: 3px;
}

.marBot4 {
  margin-bottom: 4px;
}

.marBot5 {
  margin-bottom: 5px;
}

.marBot6 {
  margin-bottom: 6px;
}

.marBot7 {
  margin-bottom: 7px;
}

.marBot8 {
  margin-bottom: 8px;
}

.marBot9 {
  margin-bottom: 9px;
}

.marBot10 {
  margin-bottom: 10px;
}

.marBot15 {
  margin-bottom: 15px;
}

.marBot20 {
  margin-bottom: 20px;
}

.marBot25 {
  margin-bottom: 25px;
}

.marBot30 {
  margin-bottom: 30px;
}

.marBot35 {
  margin-bottom: 35px;
}

.marBot40 {
  margin-bottom: 40px;
}

.marBot45 {
  margin-bottom: 45px;
}

.marBot50 {
  margin-bottom: 50px;
}

.marBot55 {
  margin-bottom: 55px;
}

.marBot60 {
  margin-bottom: 60px;
}

.marBot65 {
  margin-bottom: 65px;
}

.marBot70 {
  margin-bottom: 70px;
}

.marBot75 {
  margin-bottom: 75px;
}

.marBot80 {
  margin-bottom: 80px;
}

/* ================ Margin Bottom ================ */

/* ================ Margin Left ================ */
.marLeft0 {
  margin-left: 0px;
}

.marLeft2 {
  margin-left: 2px;
}

.marLeft3 {
  margin-left: 3px;
}

.marLeft4 {
  margin-left: 4px;
}

.marLeft5 {
  margin-left: 5px;
}

.marLeft10 {
  margin-left: 10px;
}

.marLeft15 {
  margin-left: 15px;
}

.marLeft20 {
  margin-left: 20px;
}

.marLeft25 {
  margin-left: 25px;
}

.marLeft30 {
  margin-left: 30px;
}

.marLeft35 {
  margin-left: 35px;
}

.marLeft40 {
  margin-left: 40px;
}

.marLeft45 {
  margin-left: 45px;
}

.marLeft50 {
  margin-left: 50px;
}

/* ================ Margin Left ================ */

/* ================ Margin Right ================ */
.marRight0 {
  margin-right: 0px;
}
.marRight2 {
  margin-right: 2px;
}

.marRight3 {
  margin-right: 3px;
}

.marRigh4 {
  margin-right: 4px;
}

.marRight5 {
  margin-right: 5px;
}

.marRight10 {
  margin-right: 10px;
}

.marRight15 {
  margin-right: 15px;
}

.marRight20 {
  margin-right: 20px;
}

.marRight25 {
  margin-right: 25px;
}

.marRight30 {
  margin-right: 30px;
}

.marRight35 {
  margin-right: 35px;
}

.marRight40 {
  margin-right: 40px;
}

.marRight45 {
  margin-right: 45px;
}

.marRight50 {
  margin-right: 50px;
}

/* ================ Margin Right ================ */

/* ================ Line Through ================ */
.lineThrough {
  text-decoration: line-through;
}

/* ================ Line Through ================ */

/* ============== Text transform ==============*/
.textUppercase {
  text-transform: uppercase;
}

.textCapitalize {
  text-transform: capitalize;
}

.textNormal {
  text-transform: normal;
}

/* ============== Text transform ==============*/

/* ============== Button Style Css ==============*/
.buttonStyle {
  height: 51px;
  min-width: 285px;
  border-radius: 8px;
  /* padding: 20px 50px 20px 50px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  outline: 0;
  box-shadow: 0px 6px 15px 0px #00000014;
}

.BtnwhiteBg {
  background: var(--white);
  border: 1px solid var(--lightGray);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.BtnwhiteBg:hover {
  background: var(--extraLightGray);
  border: 1px solid var(--lightGray);
}
.BtnDisable {
  background: var(--white);
  border: 1px solid var(--lightGray);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.BtnDisable:hover {
  background: transparent;
  border: 1px solid inherit;
}

.BtnGreenBg {
  background: var(--brightGreen);
  border: 3px solid var(--brightGreen);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.BtnGreenBg:hover {
  background: var(--darkGreen);
  border: 1px solid var(--darkGreen);
}

.BtnGreenShadeBg {
  background: var(--greenShade);
  border: 1px solid var(--brightGreen);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.BtnGreenShadeBg:hover {
  background: var(--greenShade);
  border: 1px solid var(--darkGreen);
}

.BtnTransparent {
  background: transparent;
  border: 1px solid transparent;
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.BtnTransparent:hover {
  background: transparent;
  border: 1px solid transparent;
}

.bannerSignupBtn {
  background: var(--brightGreen);
  border: 3px solid var(--brightGreen);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.bannerSignupBtn svg {
  position: relative;
  left: 0;
  -webkit-transition: left 0.3s linear;
  transition: left 0.3s linear;
}

.bannerSignupBtn:hover {
  background: var(--darkGreen);
  border: 1px solid var(--darkGreen);
}

.bannerSignupBtn:hover svg {
  left: 10px;
}

.bannerSellSaddarBtn {
  background: transparent;
  border: 3px solid var(--white);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.bannerSellSaddarBtn:hover {
  background: rgba(245, 245, 247, 0.25);
}

.bannerSellSaddarBlackBtn {
  background: transparent;
  border: 1px solid var(--darkGray);
  -webkit-transition: var(--backgroundBorderTransition);
  transition: var(--backgroundBorderTransition);
}

.bannerSellSaddarBlackBtn:hover {
  background: rgba(245, 245, 247, 0.25);
}

/* ============== Button Style Css ==============*/

/* ================ Text Truncate ================ */
.OneLineTruncate {
  display: -webkit-box;
  /* max-width: 50%; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: initial;
}

.twoLineTruncate {
  display: -webkit-box;
  /* max-width: 50%; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: initial;
}

.threeLineTruncate {
  display: -webkit-box;
  /* max-width: 50%; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: initial;
}

.fourLineTruncate {
  display: -webkit-box;
  /* max-width: 50%; */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: initial;
}

/* ================ Text Truncate ================ */

/* ============== Extra Css Start ==============*/
.fluid {
  max-width: 1200px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
}

.fluid__instructions {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 20px;
}

.fixed__instructions {
  -ms-flex: 1 1;
  flex: 1 1;
  margin: 0 20px;
}

/* ============== Extra Css Start ==============*/

/* ============== App Radio Button ==============*/
.option-input {
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px !important;
  transition: all 0.15s ease-out 0s;
  background: none;
  border: 1px solid var(--lightGray) !important;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
  outline: none;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.option-input:hover {
  background: var(--extraLightGray);
}

.option-input:checked {
  background: #fff;
  background: var(--brightGreen);
  border: 1px solid var(--darkGreen) !important;
}

.option-input:checked:before {
  height: 10px;
  width: 10px;
  /* position: absolute;
  top: 3px;
  left: 3px; */
  content: "";
  display: inline-block;
  font-size: 27px;
  text-align: center;
  background: var(--white);
  border-radius: 7px;
}

.option-input:checked:after {
  -webkit-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

.option-input.radio {
  border-radius: 12px;
}

.option-input.radio:after {
  border-radius: 50%;
}

.radioLablelBox input {
  width: auto;
  display: inline-block;
  border: none;
}

.radioLablelBox label {
  position: relative;
  top: 0;
  left: 0;
  pointer-events: auto;
  color: #565656;
  color: var(--mediumGray);
  font-size: 0.9rem;
  cursor: pointer;
}

.radioLablelBox {
  float: left;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.innerHeaderMainbox {
  min-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.innerHeaderLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.innerHeaderLeft p {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.innerHeaderRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/* ============== Inner Header ==============*/

/* ============== Detail Card ==============*/
.detailScreenCardMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.detailScreenCardInner {
  margin-bottom: 30px;
}

.detailScreenCard {
  min-height: 60px;
  border: 1px solid var(--extraLightGray);
  border-radius: 12px;
  box-shadow: 0px 6px 15px 0px #00000014;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
}

.detailScreenCardLeft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.detailScreenCardRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailScreenCardRight p {
  margin-bottom: 0;
}

.detailCardCutomerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.customerIdAction {
  background: var(--greenShade);
  min-width: 63px;
  min-height: 31px;
  border-radius: 25px;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  color: var(--darkGray);
}

.detailScreenBottomAction {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background: var(--white);
  width: 100%;
  left: 0;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* ============== Detail Card ==============*/

/* ============== Video Modal ==============*/
.videoModal.modal {
  --bs-modal-width: 50%;
  /* height: 500px; */
}
.videoModal .modal-dialog-scrollable .modal-content {
  height: 580px;
}

.videoModal .modal-content {
  /* position: absolute;
  bottom: 0px;
  border-radius: 20px 20px 0 0 !important; */
}

.videoModal .modal-dialog {
  /* margin: 0; */
}

.statementPara {
  font-size: 14px;
  color: var(--darkGray);
  font-weight: 400;
}

.statementPara a {
  color: var(--skyBlue);
  text-decoration: none;
  margin-left: 5px;
}
.toast {
  border: 1px solid var(--lightGray) !important;
  /* position: absolute;
  top: 10px; */
  box-shadow: none !important;
}
.toast-header {
  border-bottom: 1px solid var(--lightGray) !important;
}
.toast-container.bottom-0 {
  bottom: 40px !important;
  z-index: 2147493000 !important;
}
.BankListBodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
}
.bankListWrapper {
  margin-top: 25px;
  width: 100%;
}
.bankListWrapper .detailScreenCardInner {
  margin-bottom: 5px;
}
.bankListWrapper .detailScreenCardInner .detailScreenCard {
  height: 55px;
  border: 0;
  border-radius: 15px;
  box-shadow: none;
  padding: 0 0;
  box-sizing: border-box;
  cursor: pointer;
}
.bankListWrapper .detailScreenCardInner .detailScreenCardLeft {
  border: 1px solid var(--extraLightGray);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 5px 5px;
  filter: none;
}
.bankListWrapper .detailScreenCardInner .detailScreenCardLeft .UdhaarLogo {
  /* width: 30px;
  height: 30px; */
  padding: 3px 3px;
  filter: none;
}
.Toastify__toast-body {
  position: relative;
}
.Toastify__toast-body .Toastify__toast-icon {
  display: none;
}
.Toastify__toast-body div:nth-child(2) {
  text-align: left;
}
.Toastify__close-button {
  position: absolute;
  right: 10px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
  .toast {
    border: 1px solid var(--lightGray) !important;
    /* position: relative;
  top: auto; */
    /* margin: 0 auto; */
    box-shadow: none !important;
  }
  .videoModal .modal-content {
    position: absolute;
    bottom: -16px;
    border-radius: 20px 20px 0 0 !important;
  }
  .videoModal .modal-dialog {
    margin: 0;
  }
}
/* ============== Video Modal ==============*/

/* ============== Chat Woot Button Style ==============*/
.woot-widget-bubble svg#woot-widget-bubble-icon {
  display: none;
}

.woot-widget-bubble {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.woot-widget-bubble::before {
  content: "";
  width: 26px;
  height: 25px;
  display: block;
}

.woot-widget-bubble {
  bottom: 30px !important;
}
.woot-widget-bubble.woot-elements--right {
  bottom: 85px !important;
}
/* ============== Chat Woot Button Style ==============*/

/* ============== Invoice Not Found ==============*/
.invoiceNotFound {
  background: var(--white);
  min-height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* ============== Invoice Not Found ==============*/
/* New Design Css */

/* Browser Theme Css */
@media (prefers-color-scheme: dark) {
  .paymentAmountCard {
    background: var(--lightBlue);
  }
  body,
  html .homeBodyBox,
  #root,
  #root > div {
    background: var(--lightBlue);
  }
  #root div:nth-child(1) {
    /* background: var(--grayBgTwo); */
  }
  .darkGrayColor {
    color: var(--darkGray);
  }
  .newFooterInner .mediumGrayColor {
    color: var(--mediumGray);
  }
  .paymentAmountAction li a {
    background: var(--white);
  }

  .ubLogo {
    background: url(../src/assets/icons/UblogoWhite.svg);
  }
  #root {
    height: calc(100vh - 50px);
  }
  .woot-widget-bubble {
    background: #27aae1 !important;
  }
  .woot-widget-bubble::before {
    background: url(../src/assets/icons/helpIconWhite.svg) !important;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat !important;
  }
  .innerHeaderMainbox {
    background: transparent;
  }
  .innerHeaderLeft p svg {
    fill: var(--darkGray);
  }
  .transactionListInnerBox {
    background: var(--white);
  }
  .transactionListInnerBox::before {
    background: url("./assets/images/receiptLineImage.svg");
  }

  .transactionListInnerBox::after {
    background: url("./assets/images/receiptLineImage.svg");
  }
  table thead th p,
  table tr td p {
    color: var(--darkGray);
  }
}
@media (prefers-color-scheme: light) {
  .paymentAmountCard {
    /* background: var(--lightBlue); */
  }
  body,
  html,
  .homeBodyBox {
    /* background: var(--white); */
  }
  .paymentAmountAction li a {
    background: var(--white);
  }
  .woot-widget-bubble::before {
    background: url(../src/assets/icons/helpIconWhite.svg) !important;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat !important;
  }
  .woot-widget-bubble {
    background: #27aae1 !important;
  }
  .innerHeaderMainbox {
    background: transparent;
  }
  .transactionListInnerBox {
    background: var(--white);
  }
  .innerHeaderLeft p svg {
    fill: var(--darkGray);
  }
  .transactionListInnerBox::before {
    /* background: url("./assets/images/receiptlinestopNew.svg"); */
    background: url("./assets/images/receiptLineImage.svg");
  }

  .transactionListInnerBox::after {
    background: url("./assets/images/receiptLineImage.svg");
  }
  table thead th p,
  table tr td p {
    color: var(--darkGray);
  }
}
/* Browser Theme Css */

iframe {
  display: none;
}
#root {
  height: 100%;
}

.newLoaderBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #000000 0%, rgba(29, 29, 29, 0.69) 98.85%);
  position: absolute;
  top: 0px;
  z-index: 99;
  width: 100%;
  left: 0;
}

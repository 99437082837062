/* ============== Payment Amount Card ==============*/
.paymentAmountCard {
  /* min-height: 105px; */
  color-scheme: light dark;
  margin-top: 6px;
  margin-bottom: 20px;
}
.paymentAmountCardInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 105px;
  padding: 10px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--extraLightGray);
}
.amountPara {
  line-height: 24px;
}
.paymentAmountAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.paymentAmountAction li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
}

.paymentAmountAction li a {
  /* background: var(--white); */
  color: var(--darkGray);
  font-size: 14px;
  font-weight: 500;
  min-width: 108px;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid var(--lightGray);
  border-radius: 28px;
  margin-right: 15px;
}
.paymentAmountCardInner h1 {
  position: relative;
  width: 100%;
  text-align: center;
}
.downloadPdfIcon {
  position: absolute;
  right: 10px;
  top: -2px;
  cursor: pointer;
}
.rightArrowIcon {
  position: relative;
  top: -2px;
}
/* ============== Payment Amount Card ==============*/

.detailBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 316px;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--extraLightGray);
}
.detailBoxAppIcon {
  height: 40px;
}
.detailBoxAppIcon svg {
  width: 35px;
  height: 35px;
}
.detailBoxAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.detailBoxAction p {
  margin-right: 30px;
}
.detailBoxAction p span {
  display: block;
}
.detailBoxAction p:last-child {
  margin-right: 0;
}

.cardNumberBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* min-height: 316px; */
  margin-bottom: 30px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--mediumBlue);
}
.cardCvcBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0 16px;
}
.homeActionBox.cardActionBox {
  position: fixed;
  bottom: -25px;
  left: 0;
}

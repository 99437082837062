/* Container styling */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  padding: 0px 0;
  border: 1px solid var(--lightGray);
  transition: border-color 0.3s ease;
  border-radius: 8px;
}

/* Focused state */
.input-container.focused {
  border-color: var(--barclayBlue);
}

/* Floating label styling */
.floating-label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 14px;
  color: var(--lightGrayTwo);
  transition: all 0.3s ease;
  pointer-events: none;
}

/* Floating label when focused or input has value */
.input-container.focused .floating-label,
.input-container.focused input:not(:placeholder-shown) + .floating-label {
  top: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--barclayBlue);
  background: var(--white);
  /* background: var(--lightBlue); */
  padding: 2px 4px;
  min-width: 40px;
}

/* Input styling */
.custom-input {
  width: 100%;
  padding: 12px 48px 13px 15px;
  border: none;
  outline: none;
  font-size: 14px;
  color: var(--mediumGray);
  background: none;
  font-weight: 500;
}
.custom-input::placeholder {
  color: transparent;
}
.prfixIcon {
  position: absolute;
  right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 11px;
}

.bankDetailBoxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 80px;
  margin-bottom: 15px;
  padding: 15px 15px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--extraLightGray);
  cursor: pointer;
}
.bankDetailBoxInnerLeft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var(--lightGray);
  border-radius: 8px;
  margin-right: 10px;
}
.bankDetailBoxInnerLeft svg {
width: 20px;
  height: 20px;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bankDetailBoxInnerRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  position: relative;
}
.copyTag {
  position: absolute;
  right: 0;
  width: 45px;
  height: 16px;
  border-radius: 7px;
  padding: 0 7px;
  background: var(--greenShade);
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Position the main button */
.floating-button {
  position: fixed;
  bottom: 75px;
  right: 20px;
  border-radius: 17px;
  width: 35px;
  height: 35px;
  font-size: 16px;
  z-index: 1000;
  background: var(--searGreen);
  border: 1px solid var(--searGreen);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.floating-button:hover,
.floating-button:focus,
.floating-button:active {
  background: var(--searGreen) !important;
  border: 1px solid var(--searGreen) !important;
}
.floating-button.floatingExpanded {
  background: var(--white);
}
.floating-button.floatingExpanded:focus,
.floating-button.floatingExpanded:hover {
  background: var(--white) !important;
}
.floating-button span {
  font-size: 6px;
  line-height: 5px;
  color: var(--white);
}
.floating-button.floatingExpanded span {
  color: var(--searGreen);
}
.floating-button span svg {
  width: 26px;
  height: 26px;
}
.chatClose svg {
  width: 16px !important;
  height: 16px !important;
}
/* Container for options */
.options {
  position: fixed;
  bottom: 20px;
  right: 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 5;
  bottom: 120px;
  z-index: 10;
  transform: scale(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

/* Expanded state for options */
.options.expanded {
  transform: scale(1); /* Show options */
}

/* Individual option buttons */
.option-button {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.option-button svg {
  width: 24px;
  height: 24px;
}
.option-button.btn-info,
.option-button.btn-primary {
  background: var(--searGreen) !important;
  border: 1px solid var(--searGreen);
}
.option-button.btn-primary svg {
  width: 18px !important;
  height: 18px !important;
}

.option-button.btn-primary svg path {
  fill: var(--white);
}

body {
  font-family: var(--font_Outfit) !important;
  background: var(--lightBlue) !important;
}
input:focus {
  box-shadow: none !important;
}
button:focus {
  box-shadow: none !important;
}
/* header {
  height: 60px;
} */
.newHeader {
  height: 57px;
  min-height: 57px;
  border-bottom: 1px solid var(--extraLightGray);
  box-shadow: none;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 99;
}
.headerLeftWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.headerRightWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.headerLeftWrapper p,
.headerRightWrapper p {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.headerwatsappIcon {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

/* ============== Transaction List ==============*/
.transactionListMainBox {
  background: var(--lightBlue);
  height: calc(100% - 55px);
  padding-bottom: 10px;
}
.transactionBottomAction {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background: var(--white);
  width: 100%;
  left: 0;
  min-height: 79px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--extraLightGray);
}

.transactionListInnerBox {
  width: 100%;
  margin: 20px auto 160px;
  height: auto;
  padding: 18px 15px 10px;
  position: relative;
}

.transactionListInnerBox::before {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  left: 0;
  top: -6px;
  background-size: cover;
}

.transactionListInnerBox::after {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  left: 0px;
  bottom: -6px;
  background-size: cover;
  transform: rotate(180deg);
}
.receiptBoxItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--extraLightGray);
  padding: 11px 0px;
}
.receiptBoxItemLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}
.receiptBoxItemRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}
.transactionListRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 5px;
  border-bottom: 1px solid var(--extraLightGray);
}

.transactionListRowLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.transactionListRowRight {
}

.padLefttoRight {
  padding-left: 5px;
  padding-right: 5px;
}

.balanceBox {
  margin-bottom: 0;
  background: var(--brigthRed);
  min-width: 52px;
  min-height: 15px;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 10px;
  color: var(--darkGray);
  margin-top: 2px;
}

.noBorder {
  border: 0;
}

.transactionListTable {
  width: 100%;
}

.transactionListTable table {
  width: 100%;
}
.transactionListTable table tr:last-child {
  border-bottom: 0;
}

table thead th {
  padding: 11px 0px;
  text-align: center;
  width: 25%;
}

table thead th p {
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  color: var(--lightGrayTwo);
}

table tr {
  border-bottom: 1px solid var(--extraLightGray);
}

table tr td {
  padding: 15px 5px;
  text-align: center;
  width: 25%;
}

table tr td p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--darkGray);
}

table thead th:nth-child(1),
table tr td:nth-child(1) {
  text-align: left;
  width: 30%;
}

table thead th:nth-child(2),
table tr td:nth-child(2) {
  width: 20%;
}

table thead th:nth-child(4),
table tr td:nth-child(4) {
  text-align: right;
}

.transactionItemBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.transactionItemImage {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid var(--extraLightGray);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.transactionItemImage img {
  width: 14px;
  height: 14px;
}
.itemsListAccordion.accordion {
  border: 0;
  --bs-accordion-border-color: var(--white);
  padding: 0;
}
.itemsListAccordion .receiptBoxItem {
  padding: 15px 0;
}
.itemsListAccordion .accordion-item {
  border: 0 !important;
  border-radius: 0 !important;
}
.itemsListAccordion .accordion-button {
  padding: 0;
}
.itemsListAccordion .accordion-button::after {
  display: none;
}
.itemsListAccordion .receiptBoxItemRight svg {
  transition: transform 0.2s ease-in-out;
}
.itemsListAccordion .accordion-button:not(.collapsed) {
  background: transparent;
}
.itemsListAccordion .accordion-button:not(.collapsed) .receiptBoxItemRight svg {
  transform: rotate(-180deg);
}
.itemsListAccordion .accordion-body {
  padding: 0;
}

/* ============== Transaction List ==============*/

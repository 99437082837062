.BodyContentTop.accountInfo {
  background: transparent;
  border-radius: 0px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  margin: 5px 0 0;
  padding: 20px 15px 5px;
}
.accountInfo .totalHeading {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.accountInfo .totalAmount {
  margin-top: 5px;
}
.accountInfo .totalAmount p {
  font-size: 1.6rem;
  text-align: center;
}
.accountInfo .payableIcon {
  width: 25px;
  height: 25px;
}
.inputBoxMainCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.inputBoxMain {
  /* background: #fff;
    border-radius: 18px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1); */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  float: left;
  /* margin: 25px 0 15px; */
  margin: 4px 0;
  padding: 3px 0;
  width: 100%;
  border-top: none;
}
.inputBoxHeading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.inputBoxHeading p {
  text-transform: capitalize;
  color: #565656 !important;
  margin-bottom: 0;
  font-size: 0.9rem;
  /* margin-left: -27px; */
  float: left;
  font-weight: 400;
}
.inputBoxinput {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  width: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
}
.inputBoxinput span {
  /* display: flex;
  flex-direction: row;
  align-items: center; */
}
.inputBoxinput input {
  width: 85%;
  border: none;
  border-bottom: 1px solid;
  border-color: #ddd;
  transition: all 0.4s ease;
  padding: 8px 12px;
  color: var(--mediumGray);
  font-size: 0.9rem;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
}
.inputBoxinput input.form-control::placeholder {
  color: #ddd;
  /* opacity: 0.5; */
}
.inputBoxinput input:hover {
}
.inputBoxinput input:focus {
  border-color: var(--mainColor);
  color: var(--mainColor);
  outline: 0;
  text-shadow: none;
  box-shadow: 0 0 0 1px var(--mainColor), 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05) !important;
}
.inputPhoneicon {
  background: url("../../../assets/images/phone.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 23px;
  height: 21px;
  display: inline-block;
  margin-right: 8px;
}
.inputBoxbutton {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}
.nextButtondisable {
  background: #4ac600;
  border: 1px solid #4ac600;
  border: 1px solid #cecece !important;
  color: #cecece !important;
}
.nextButtondisable:hover {
  background: #f5f5f7;
}
.nextButton {
  background: #4ac600;
  border: 1px solid #4ac600;
  color: #fff;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 9px 0;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.nextButton:hover {
  background: #048900;
}
.seeprocessButton {
  background: #fff;
  color: #3b0c36;
  width: auto;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #3b0c36;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 7px;
  font-size: 0.8rem;
}
.seeprocessButton:hover {
  background: #f5f5f7;
}
.BodyContentBottom.accountInfoBottom {
}
.accountInfoBottomInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.BodyContentBottom .accountInfoBottomInner p {
  color: #565656;
}
.accountInfoBottomInner span:nth-child(1) {
}
.accountInfoBottomInner span:nth-child(2) {
}
.acbottomButtonMain {
}
.easypyIcon {
  background: url("../../../assets/images/EP.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 66px;
  height: 33px;
  display: inline-block;
  margin-right: 7px;
  margin-left: 3px;
}
.easypyMainIcon {
  background: url("../../../assets/images/Easypaisa.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 100%;
  min-height: 27px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 6px;
}
.accountInfoinput.inputBoxinput {
  flex-direction: column;
  float: left;
  width: 100%;
}
.accountInfoinput.inputBoxinput span {
  width: 15%;
}
.accountInfoinput.inputBoxinput span img {
  width: 100%;
}
.accountInfoinput.inputBoxinput input {
  width: 100%;

  /* margin-left: 10px; */
}
.accountInfoinput.accountInfoinputUrdu.inputBoxinput input {
  text-align: right;
}
.accountInfoinput.inputBoxinput input[type="number"] {
  /* -moz-appearance: textfield; */
}
.accountInfoinput.inputBoxinput input[type="number"]::-webkit-inner-spin-button,
.accountInfoinput.inputBoxinput
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}
.accountInfoBottomInner.accountInfoEasyPaisa {
}
.accountInfoBottomInner.accountInfoEasyPaisa p {
  margin-bottom: 0;
  display: flex;
  text-align: left;
  /* border: 1px solid; */
  float: left;
  width: auto;
}
.accountInfoBottomInner.accountInfoEasyPaisa p span {
  justify-content: flex-start;
  display: inline-block;
}
.BodyContentBottom.accountInfoBottom span:nth-child(2) {
  width: 130px;
  margin: 0 9px 0 0px;
}
.BodyContentBottom.accountInfoBottom span img {
  width: 100%;
  margin: 0 5px;
}
.BodyContentBottom.accountInfoBottom span:nth-child(3) {
  margin-right: 10px;
}
.inputBoxinput .error {
  color: #e51a32;
}
.mPinModal {
}
.modal-dialog.mPinModal {
  max-width: 35%;
}
.modal-dialog.mPinModal .modal-content {
  min-height: 300px;
  direction: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .mPinText p {
  color: #414141;
  font-size: 1.1rem;
  text-align: center;
} */
.didnotRecieveCode {
  display: flex;
}
.didnotRecieveCode p {
  color: #565656;
  font-size: 0.9rem;
}
.waitingforCode {
}
.waitingforCode p {
  color: #565656;
  font-size: 0.9rem;
}
.tryAgainButton {
  background: #3b0c36;
  color: #fff !important;
  font-size: 0.9rem !important;
  border-radius: 5px;
  padding: 8px 0;
  width: 135px;
  margin: 23px auto 5px !important;
  text-align: center;
}
.verification_btn {
  background: var(--greenShade);
  color: var(--brightGreen);
  padding: 11px 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--brightGreen);
  border-radius: 5px;
}
.verification_btn span:nth-child(1) {
  margin-right: 10px;
}
.verification_btn.verification_btnUrdu {
  flex-direction: row-reverse;
}
.verification_btn.verification_btnUrdu span:nth-child(1) {
  margin-right: 0px;
  margin-left: 10px;
}
.callBtnPayment {
  background: transparent;
  color: #414141;
  padding: 0;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin: 10px 0 15px;
  padding: 0 5px;
}
.callBtnPayment span:nth-child(1) {
  margin-right: 8px;
}
.verificationActionSectiion {
  /* border-top: 1px solid #efefef; */
  /* border-bottom: 1px solid #efefef; */
  padding: 15px 5px;
}
.verifyCodeAction {
  position: relative;
  bottom: 30px;
  margin: 0 auto;
  padding: 0 0px;
  z-index: 99;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.waitingCodeTex {
  font-size: 13px;
  color: var(--mediumGray);
  margin: 20px 0 0;
  font-weight: 400;
}
.waitingCodeTex.waitingCodeTexUrdu {
  text-align: right;
}
.verificationInputInnerMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: -5px auto 0;
}
.verificationInputInnerMain > div {
  gap: 10px;
}
.verificationBox {
  background: transparent !important;
}
.verificationInputInnerCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin: 6px 0px 10px;
  height: 46px;
}
.verificationInputInnerCont input {
  width: 100% !important;
  border: 1px solid var(--extraLightGray) !important;
  color: var(--darkGray);
  padding: 10px 0 15px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 8px;
  caret-color: var(--skyBlue); /* Change cursor color to red */
  /* background: var(--white) !important; */
}
.verificationInputInnerCont input:focus {
  color: #3d0137;
  border: 1px solid var(--skyBlue) !important;
}
.modal-content {
  border-radius: 20px !important;
  padding: 15px 10px !important;
}
.login_page_top_content {
  margin-bottom: 40px;
}
.login_page_top_content.login_page_top_contentUrdu {
  text-align: right;
}
.login_page_top_content h4 {
  color: var(--darkGray);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.login_page_top_content h4 b {
  font-weight: 500;
  color: var(--skyBlue);
}
.login_page_top_content p {
  font-size: 14px;
  color: var(--mediumGray);
  font-weight: 300;
}
.enterDigitTex {
  font-size: 14px;
  color: var(--mediumGray);
  text-align: center;
  margin-bottom: 0;
}
.waitingForCodeNumber {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 15px;
  min-height: 25px;
}
.waitingForCodeNumber .waitingCodeTex {
  font-size: 0.9rem;
  color: #565656;
  margin: 0px 0 0;
  text-align: center;
}
.waitingLoad {
  margin-left: 5px;
  position: relative;
  top: 5px;
}
.waitingLoad .loaderCircle {
  width: 16px !important;
  height: 16px !important;
  color: var(--brightGreen) !important;
  border-width: 0.19em;
}

.waitingForCodeNumber .loaderCircle {
  color: var(--brightGreen) !important;
}
.errorMsg {
  color: red;
  font-size: 0.8rem;
  text-align: center;
}
.errorMsg1 {
  color: red;
  font-size: 0.8rem;
}
.errorMsgInvalidCode {
  color: red;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 4px;
  height: 20px;
}
.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
}
.spinnerContainer .loaderCircle {
  width: 45px !important;
  height: 45px !important;
  color: var(--mainColor) !important;
}
.loaderCircle.nextButtonLoader {
  width: 24px !important;
  height: 24px !important;
  color: var(--white) !important;
}
.loaderCircle .sr-only {
  font-size: 0;
}
.inputError {
  color: #e51a32;
  font-size: 11px;
  text-align: left;
  width: 100%;
  position: relative;
  top: 8px;
}
.accountInfoInputBoxCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
.labelClass {
  width: 100%;
  font-size: 0.9rem;
  color: var(--mediumGray);
  margin-bottom: 10px;
}
.accountInfoMethodIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70px !important;
  position: absolute;
  right: 10px;
  height: auto;
}
.accountInfoMethodIcon.accountInfoMethodIconUrdu {
  left: 10px;
}
.accountInfoMethodIcon img {
  width: 100%;
}
.bankTransferIcon {
  width: 25px !important;
  position: absolute;
  right: 0;
}
.confirmingPara {
  float: left;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
  color: var(--mediumGray);
}
.confirmingPara.confirmingParaUrdu {
  text-align: right;
}
.confirmingPara span {
  color: var(--mediumGray);
  text-decoration: underline;
}
.backIconMain {
  position: absolute;
  right: 0;
  top: -74px;
  z-index: 9;
}
.backIconMain.backIconMainUrdu {
  left: 0;
}
.backIconMain:hover {
  cursor: pointer;
}
.backIconMain.CloseDisable:hover {
  cursor: auto;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
  .accountInfoinput.inputBoxinput span {
    width: 20%;
  }
  .accountInfoinput.inputBoxinput input {
    width: 100%;
    /* margin-left: 10px; */
  }
  .inputBoxinput {
    margin-top: 0;
  }
  .accountInfoBottomInner.accountInfoEasyPaisa p {
    margin-bottom: 0;
    display: inline-block;
    text-align: left;
    float: left;
    width: 60%;
  }
  .backIconMain {
    top: 6px;
  }
  .verifyCodeAction {
    position: relative;
    bottom: 0;
    margin: 0 auto 30px;
  }
  .verifyCodeActionBottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .verificationActionSectiion {
    padding: 20px 5px 5px;
  }
  .modal-dialog.mPinModal {
    max-width: 100%;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

:focus {
  outline: none;
}

.BodyContent {
  float: left;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  padding: 140px 20px 30px;
}
.paymentSimpleBox {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentSimpleBox .paragraphSuffix {
  margin-bottom: 0;
  position: relative;
  top: -2px;
}
.EmptyHeader .BodyContent {
  padding: 80px 20px 10px;
}

.accountinCOnt {
  /* background: #f5f5f7; */
  background: #fff;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.BodyContentTop {
  background: #fff;
  border-radius: 0;
  box-shadow: none;
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
  /* float: left; */
  margin: 5px 0 25px;
  padding: 20px 0px;
  width: 85%;
  position: relative;
  z-index: 99;
}

.totalHeading {
  text-align: center;
  /* border-bottom: 1px solid #f5f5f7; */
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.totalHeading p {
  color: #414143 !important;
  font-size: 1rem !important;
  font-weight: 400;
  margin: 0;
  /* text-transform: capitalize !important; */
}

.accountinCOnt .totalAmount {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.accountinCOnt .totalAmount p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--mediumGray);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  margin: 0;
}

.accountinCOnt .totalAmount span {
  width: 50%;
}

.accountinCOnt .totalAmount span:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.accountinCOnt .totalAmount span:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--mediumGray);
}

.payableIcon {
  background: url("../../../assets/images/Arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
}

.timeandDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f7;
  padding-bottom: 15px;
  margin-bottom: 12px;
}

.timeandDetail p {
  color: #565656;
  font-size: 0.6rem;
  width: 50%;
  margin: 0;
  font-weight: 300;
  line-height: 18px;
}

.timeandDetail p:nth-child(1) {
  text-align: left;
}

.timeandDetail p:nth-child(2) {
  text-align: right;
}

.timeandDetail span {
}

.timeandDetail b {
  padding: 0 2px;
}

.accountinCOnt .BodyContentMiddle {
  margin: 10px 0 20px !important;
  float: left;
  width: 100%;
}

.BodyContentMiddle p {
  color: #565656;
  margin: 15px 0 18px;
  font-size: 0.8rem;
  text-align: center;
}

.payNowButton {
  background: #4ac600;
  color: #fff;
  width: 200px;
  text-align: center;
  margin: 18px auto 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #4ac600;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 9px 0;
  font-size: 0.9rem;
}

.payNowButton:hover,
.payNowButton:focus {
  background: #048900;
}

.payNowButton span {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
}

.payNowButtonicon {
  background: url("../../../assets/images/checkmark_white.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 22px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}

.seeDetailButton {
  background: transparent;
  color: #27aae1;
  width: 50%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  padding: 0;
  font-size: 0.9rem;
  text-decoration: underline;
  outline: none;
}

.seeDetailButton:hover {
  background: transparent;
}

.seeDetailButton span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seeDetailButtonicon {
  background: url("../../../assets/images/SeeDetails_purple.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 5px;
}

.accountinCOnt .seeDetailButton {
  margin-bottom: 5px;
  margin-top: 5px;
}

.accountInfoTopmessageLeft span {
  color: #27aae1;
  margin-left: 5px;
}

.accountInfoTopmessageRight {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.accountInfoTopmessageRight p {
  margin: 0;
  line-height: 26px;
  font-size: 0.9rem;
  color: #414143;
}

.accountInfoTopmessageRight span {
  color: #27aae1;
  margin-left: 5px;
}

.safesecurIcon {
  background: url("../../../assets/images/Safe&Secure.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-right: 8px;
}

.dateCont {
  /* margin-bottom: 15px;
      margin-top: 15px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  /* border-top: 1px solid #f5f5f7; */
}

.dateCont p {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--darkGray);
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  width: 100%;
  margin: 0;
  padding: 5px 0;
}

.dateCont.dateContUrdu p {
  justify-content: flex-end;
}

.dateContUrdu p span b {
  margin: 0 3px;
}

.dateCont span {
  /* width: 60%; */
}

.dateContUrdu.dateCont span:nth-child(1) {
  flex-direction: row-reverse;
}

.dateCont span:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dateCont span:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--mediumGray);
  margin-left: 5px;
}

.dateCont span:nth-child(2) b {
  color: var(--lightGray);
  font-weight: 400;
  padding: 0 5px;
}

.invalidStatusCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 64px;
  height: calc(100vh - 110px);
  background: #fff;
}

.invalidStatusCont p {
  font-size: 1.6rem;
  color: #414143;
}

.trustedBusiness {
  margin-top: 20px;
}
.paymentMethodWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 174px;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: var(--white);
  border-radius: 18px;
  color-scheme: light dark;
  box-shadow: 0px 6px 15px 0px #0000000a;
  border: 1px solid var(--extraLightGray);
}
.NotFoundWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: calc(100vh - 160px);
  position: relative;
}
.NotFoundMainWrapper .newFooter {
  position: absolute;
  bottom: 20px;
}
.notFoundImage {
  /* min-height: 200px; */
  height: auto;
  width: auto;
  margin-bottom: 30px;
}
.nextButton.width80 {
  width: 80%;
  margin-top: 20px;
}
@media only screen and (min-device-width: 321px) and (max-device-width: 768px) {
  .notFoundImage {
    width: 100%;
    margin-bottom: 30px;
  }
  .BodyContent {
    padding: 90px 20px 30px;
  }

  .BodyContentTop {
    width: 100%;
    padding: 20px 5px;
  }

  .EmptyHeader .BodyContent {
    padding: 80px 10px 30px;
  }

  .BodyContent::before {
    display: none;
  }
}

@media only screen and (max-device-width: 320px) {
  .BodyContentTop {
    width: 100%;
    padding: 20px 5px;
  }

  .EmptyHeader .BodyContent {
    padding: 80px 10px 30px;
  }

  .BodyContent::before {
    display: none;
  }
}

.mPinTrustBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mPinTrustBox p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mPinImageBox {
  margin: 100px 0;
}
.mpinScreen {
  background: transparent !important;
}

/* ============== App Radio Button ==============*/

.optionListMainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border: 1px solid var(--lightBlue);
  border-radius: 18px;
  padding: 10px 10px 0;
}

.optionListInnerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--extraLightGray);
  padding: 14px 8px 14px 8px;
  margin-bottom: 5px;
}

.optionListMainBox .optionListInnerBox:last-child {
  border: none;
  padding: 14px 8px 10px;
}

.appCheckBoxRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.appCheckBoxRow input {
  position: absolute;
  left: 0;
}

.optionListbody {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;
}

.optionListbody p,
.optionListbody span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
}

.homeBodyBox {
  background: transparent;
  padding-top: 57px;
  height: 100%;
  /* border: 1px solid red; */
}

.homeActionBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  margin-top: 30px;
  position: sticky;
  width: 100%;
  background: var(--white);
  bottom: 0px;
  z-index: 9;
  margin-bottom: 25px;
}
button .spinner-border span {
  font-size: 0;
}
.optionListRightBox {
  position: absolute;
  right: 6px;
}

.imgResponsive {
  height: 30px;
  width: 30px;
  cursor: pointer;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
}
/* ============== Inner Header ==============*/
